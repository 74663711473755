const confirmation_email_fr = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Confirmation de Réservation</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    @media screen and (max-width: 600px) {
      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
</head>
<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <center><h2>Confirmation de Réservation ID-XXX</h2></center>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <p>
              Bonjour XXX,<br><br>
              Merci de nous avoir choisis pour votre location de voiture à Madère ! Nous avons le plaisir de vous informer que les dates et le véhicule demandés sont disponibles. Votre réservation auprès d'Auto Rent-a-Car est confirmée, et ses détails sont les suivants :
            </p>
            <!-- Reservation Details -->
            <p>
              <strong>Détails de la réservation:</strong><br>
              - Date de prise en charge:</strong> XXX<br>
              - Lieu de prise en charge:</strong> XXX<br>
              - Date de retour:</strong> XXX<br>
              - Lieu de retour:</strong> XXX
            </p>
            <!-- Car details -->
            <p>
              <strong>Détails de la voiture:</strong><br>
              - Nombre de voitures:</strong> XXX<br>
              - Type de voiture:</strong> Groupe XXX (Transmission XXX - XXX)<br>
              - Nombre de conducteurs supplémentaires:</strong> XXX<br>
              - Siège auto pour tout-petit (0-3 ans):</strong> XXX<br>
              - Siège auto pour enfant (4-7 ans):</strong> XXX<br>
              - Rehausseur (8-12 ans):</strong> XXX<br>
              - Assurance:</strong> XXX<br>
            </p>
            </p>
            <!-- Pricing and Deposit -->
            <p>
              <strong>Prix total de la location (Espèces/Debit card/Credit card):</strong> XXX<br>
              <strong>Caution (Espèces/VISA Credit card):</strong> XXX
            </p>
            </p>
            <!-- Additional Information -->
            <br>
            <p style="text-align: justify">
              En ce qui concerne le paiement, il se fait uniquement au moment de la prise en charge. Nous acceptons les paiements en espèces (euros) et par les principales cartes de crédit et de débit (sauf American Express).
              Nous demandons que la caution soit versée en espèces, car il est plus facile de la restituer à la fin de la période de location. En dernier recours, il est possible de payer avec une carte de crédit VISA.
              <br></br>
              Nous vous demandons maintenant quelques informations supplémentaires, telles que le numéro de vol, le nombre de personnes arrivant, et les détails de votre hébergement à Madeira. Merci d'avance pour votre coopération!
              <br></br>
              Veuillez nous informer des détails de votre navire de croisière pour votre arrivée. Nous serons prêts à vous accueillir au terminal des navires de croisière, identifiables par notre logo et une pancarte avec votre nom. Merci d'avance pour votre coopération!
              <br></br>
              Nous avons bien reçu toutes les informations nécessaires. Si vous avez d'autres questions ou avez besoin d'assistance, n'hésitez pas à nous contacter. Merci, et à bientôt!
            </p>
            <br>
            <!-- Closing Part -->
            <p>
              Cordialement,<br>
              XXX<br><br>
              Auto Rent-a-Car<br>
              +351 918888307 (WhatsApp disponible)<br>
              www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. Tous droits réservés.
          </p>
        </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default confirmation_email_fr;