const confirmation_email_de = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Buchungsbestätigung</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    @media screen and (max-width: 600px) {
      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
</head>
<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <center><h2>Buchungsbestätigung - Reservierungs ID-XXX</h2></center>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <p>
              Hallo XXX,<br><br>
              Vielen Dank, dass Sie sich für uns entschieden haben, wenn Sie einen Wagen in Madeira mieten möchten! Wir freuen uns, Ihnen mitteilen zu können, dass die von Ihnen gewünschten Daten und das gewünschte Fahrzeug verfügbar sind. Ihre Buchung bei Auto Rent-a-Car ist bestätigt, und die Details sind wie folgt:
            </p>
            <!-- Reservation Details -->
            <p>
              <strong>Reservierungsdetails:</strong><br>
              - Abholdatum:</strong> XXX<br>
              - Abholort:</strong> XXX<br>
              - Rückgabedatum:</strong> XXX<br>
              - Rückgabeort:</strong> XXX
            </p>
            <!-- Car details -->
            <p>
              <strong>Fahrzeugdetails:</strong><br>
              - Anzahl der Fahrzeuge:</strong> XXX<br>
              - Fahrzeugtyp:</strong> Gruppe XXX (Getriebe XXX - XXX)<br>
              - Anzahl der zusätzlichen Fahrer:</strong> XXX<br>
              - Kindersitz (0-3 Jahre):</strong> XXX<br>
              - Kindersitz (4-7 Jahre):</strong> XXX<br>
              - Sitzerhöhung (8-12 Jahre):</strong> XXX<br>
              - Versicherung:</strong> XXX<br>
            </p>
            </p>
            <!-- Pricing and Deposit -->
            <p>
              <strong>Gesamtmietpreis (Bargeld/Debitkarte/Kreditkarte):</strong> XXX<br>
              <strong>Kaution (Bargeld/VISA-Kreditkarte):</strong> XXX
            </p>
            </p>
            <!-- Additional Information -->
            <br>
            <p style="text-align: justify">
              Die Bezahlung erfolgt erst bei der Abholung. Wir akzeptieren Zahlungen in bar (Euro) und mit den gängigen Kredit- und Debitkarten (außer American Express).
              Wir bitten Sie, die Kaution in bar zu hinterlegen, da es einfacher ist, sie Ihnen am Ende des Mietzeitraums zurückzugeben. In letzter Instanz ist es möglich, mit einer VISA-Kreditkarte zu bezahlen.
              <br></br>
              Wir bitten nun freundlich um weitere Informationen, wie die Flugnummer, die Anzahl der ankommenden Personen und Ihre Unterkunftsdetails in Madeira. Vielen Dank im Voraus für Ihre Kooperation!
              <br></br>
              Teilen Sie uns bitte freundlich die Details Ihres Kreuzfahrtschiffs für Ihre Ankunft mit. Wir werden bereit sein, Sie am Kreuzfahrtterminal zu begrüßen, erkennbar an unserem Logo und einem Schild mit Ihrem Namen. Vielen Dank im Voraus für Ihre Kooperation!
              <br></br>
              Wir haben alle erforderlichen Informationen erhalten. Wenn Sie weitere Fragen haben oder Hilfe benötigen, zögern Sie bitte nicht, uns zu kontaktieren. Vielen Dank und bis bald!
            </p>
            <br>
            <!-- Closing Part -->
            <p>
              Mit freundlichen Grüßen,<br>
              XXX<br><br>
              Auto Rent-a-Car<br>
              +351 918888307 (WhatsApp verfügbar)<br>
              www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. Alle Rechte vorbehalten.
          </p>
        </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default confirmation_email_de;