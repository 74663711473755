const confirmation_email_es = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Confirmación de Reserva</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    @media screen and (max-width: 600px) {
      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
</head>
<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <center><h2>Confirmación de Reserva ID-XXX</h2></center>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <p>
              Hola XXX,<br><br>
              Gracias por elegirnos para sus necesidades de alquiler de coches en Madeira! Nos complace informarle de que las fechas y el vehículo solicitados están disponibles. Su reserva con Auto Rent-a-Car está confirmada, y sus detalles son los siguientes:
            </p>
            <!-- Reservation Details -->
            <p>
              <strong>Detalles de la reserva:</strong><br>
              - Fecha de recogida:</strong> XXX<br>
              - Lugar de recogida:</strong> XXX<br>
              - Fecha de devolución:</strong> XXX<br>
              - Lugar de devolución:</strong> XXX
            </p>
            <!-- Car details -->
            <p>
              <strong>Detalles del coche:</strong><br>
              - Número de coches:</strong> XXX<br>
              - Tipo de coche:</strong> Grupo XXX (Transmisión XXX - XXX)<br>
              - Número de conductores adicionales:</strong> XXX<br>
              - Silla para niños pequeños (0-3 años):</strong> XXX<br>
              - Silla para bebé (4-7 años):</strong> XXX<br>
              - Asiento elevador (8-12 años):</strong> XXX<br>
              - Seguro:</strong> XXX<br>
            </p>
            </p>
            <!-- Pricing and Deposit -->
            <p>
                <strong>Precio total del alquiler (Efetivo/Tarjeta de débito o crédito):</strong> XXX<br>
                <strong>Depósito (Efetivo/Tarjeta de crédito VISA):</strong> XXX
            </p>
            </p>
            <!-- Additional Information -->
            <br>
            <p style="text-align: justify">
              En cuanto al pago, sólo se realiza en el momento de la recogida. Aceptamos pagos en efectivo (euros) y con las principales tarjetas de crédito y débito (excepto American Express).
              Pedimos el depósito en efectivo, ya que es más fácil de devolver a usted al final del período de alquiler. Como último recurso, es posible con tarjeta de crédito VISA.
              <br></br>
              Ahora solicitamos amablemente información adicional, como el número de vuelo, el número de personas que llegarán y los detalles de su alojamiento en Madeira. Gracias de antemano por su cooperación!
              <br></br>
              Ahora solicitamos amablemente de los detalles de su crucero para su llegada. Estaremos listos para darle la bienvenida en el terminal de cruceros, identificables por nuestro logotipo y un letrero con su nombre. Gracias de antemano por su cooperación!
              <br></br>
              Hemos recibido toda la información requerida. Si tiene alguna pregunta adicional o necesita ayuda, no dude en ponerse en contacto. Gracias, y hasta pronto!
            </p>
            <br>
            <!-- Closing Part -->
            <p>
              Saludos cordiales,<br>
              XXX<br><br>
              Auto Rent-a-Car<br>
              +351 918888307 (WhatsApp disponible)<br>
              www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. Todos los derechos reservados.
          </p>
        </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default confirmation_email_es;